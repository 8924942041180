var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-client"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"add-client__content"},[_c('div',[_c('div',{staticClass:"add-client__title",domProps:{"textContent":_vm._s(_vm.title)}}),_c('Selector',{ref:"clientType",staticClass:"form-input add-client__selector add-client__input",attrs:{"required":"","title":'Тип контрагента*',"options":Object.keys(_vm.legalForms).map((key) => ({ id: key, name: _vm.legalForms[key] })),"select-fitrst-item":false,"default-select-item-id":_vm.form.LegalForm},on:{"handleChange":_vm.handleChangeLegalForm}}),_c('div',{staticClass:"form-input",class:[{'form-input--full' : _vm.form.Inn},{'form-input--error' : _vm.errors.Inn}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Inn),expression:"form.Inn"}],attrs:{"type":"text","required":""},domProps:{"value":(_vm.form.Inn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "Inn", $event.target.value)}}}),_c('span',{staticClass:"form-input__label"},[_vm._v("ИНН*")]),(_vm.errors.Inn && _vm.errors.Inn.length)?_vm._l((_vm.errors.Inn),function(error){return _c('div',{key:error,staticClass:"form-input__error"},[_vm._v(_vm._s(error))])}):_vm._e()],2),_c('div',{staticClass:"form-input",class:[
          {'form-input--full' : _vm.form.Name },
          {'form-input--error' : _vm.errors.Name }
        ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Name ),expression:"form.Name "}],attrs:{"rows":"5"},domProps:{"value":(_vm.form.Name )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "Name", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"form-input__label"},[_vm._v("Наименование*")]),(_vm.errors.Name && _vm.errors.Name.length)?_vm._l((_vm.errors.Name),function(error){return _c('div',{key:error,staticClass:"form-input__error"},[_vm._v(_vm._s(error))])}):_vm._e()],2),(_vm.isInternationalJuridicalLegalForm || _vm.isInternationalPhysicalLegalForm)?_c('div',{staticClass:"form-input",class:[
          {'form-input--full' : _vm.form.OksmNumber },
          {'form-input--error' : _vm.errors.OksmNumber }
        ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.OksmNumber),expression:"form.OksmNumber"}],attrs:{"rows":"5"},domProps:{"value":(_vm.form.OksmNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "OksmNumber", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"form-input__label"},[_vm._v("Код страны регистрации*")]),(_vm.errors.OksmNumber && _vm.errors.OksmNumber.length)?_vm._l((_vm.errors.OksmNumber),function(error){return _c('div',{key:error,staticClass:"form-input__error"},[_vm._v(_vm._s(error))])}):_vm._e()],2):_vm._e(),(_vm.isInternationalPhysicalLegalForm)?_c('div',{staticClass:"form-input",class:[
          {'form-input--full' : _vm.form.MobilePhone },
          {'form-input--error' : _vm.errors.MobilePhone }
        ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.MobilePhone ),expression:"form.MobilePhone "}],attrs:{"rows":"5"},domProps:{"value":(_vm.form.MobilePhone )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "MobilePhone", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"form-input__label"},[_vm._v(" Номер телефона "+_vm._s(_vm.isRequiredMobilePhone ? '*' : '')+" ")]),(_vm.errors.MobilePhone && _vm.errors.MobilePhone.length)?_vm._l((_vm.errors.MobilePhone),function(error){return _c('div',{key:error,staticClass:"form-input__error"},[_vm._v(_vm._s(error))])}):_vm._e()],2):_vm._e(),(_vm.isInternationalPhysicalLegalForm)?_c('div',{staticClass:"form-input",class:[
          {'form-input--full' : _vm.form.EpayNumber },
          {'form-input--error' : _vm.errors.EpayNumber }
        ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.EpayNumber ),expression:"form.EpayNumber "}],attrs:{"rows":"5"},domProps:{"value":(_vm.form.EpayNumber )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "EpayNumber", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"form-input__label"},[_vm._v(" Номер электронного средства платежа "+_vm._s(_vm.isRequiredEpayNumber ? '*' : '')+" ")]),(_vm.errors.EpayNumber && _vm.errors.EpayNumber.length)?_vm._l((_vm.errors.EpayNumber),function(error){return _c('div',{key:error,staticClass:"form-input__error"},[_vm._v(_vm._s(error))])}):_vm._e()],2):_vm._e(),(_vm.isInternationalJuridicalLegalForm)?_c('div',{staticClass:"form-input",class:[
          {'form-input--full' : _vm.form.RegNumber },
          {'form-input--error' : _vm.errors.RegNumber }
        ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.RegNumber ),expression:"form.RegNumber "}],attrs:{"rows":"5"},domProps:{"value":(_vm.form.RegNumber )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "RegNumber", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"form-input__label"},[_vm._v("Регистрационный номер либо его аналог")]),(_vm.errors.RegNumber && _vm.errors.RegNumber.length)?_vm._l((_vm.errors.RegNumber),function(error){return _c('div',{key:error,staticClass:"form-input__error"},[_vm._v(_vm._s(error))])}):_vm._e()],2):_vm._e()],1),_c('div',{staticClass:"add-client__group-btns"},[(_vm.errors.global && _vm.errors.global.length)?_vm._l((_vm.errors.global),function(error){return _c('div',{key:error,staticClass:"form-input__error"},[_vm._v(_vm._s(error))])}):_vm._e(),_c('div',{staticClass:"add-client__btns-wrapper"},[_c('ButtonElement',{staticClass:"add-client__save-btn",attrs:{"text":"Сохранить","disabled":_vm.isDisabledSaveBtn},on:{"click-button":function($event){_vm.isClientForInitialContract ? _vm.createInitialClient() : _vm.createDirectClient()}}}),_c('ButtonElement',{staticClass:"add-client__cancel-btn button--empty",attrs:{"text":'Отменить'},on:{"click-button":_vm.closeDialog}})],1)],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }